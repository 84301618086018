import React from 'react';
import Head from 'next/head';
import { GetServerSideProps } from 'next';

import Landing from '@common-lib/modules/landing/screens/Landing';

import { Page } from '@web/types';
import PageWrapper from '@web/components/layout/PageWrapper';
import debugPage from '@web/utils/logger';

const Root: Page<{ isKras: boolean }> = ({ isKras }) => {
	return (
		<>
			<Head>
				<title>{isKras ? 'Terrasactie' : 'Verras op het Terras'}</title>
			</Head>
			<PageWrapper>
				<Landing isKras={isKras} />
			</PageWrapper>
		</>
	);
};

export const getServerSideProps: GetServerSideProps<{
	isKras: boolean;
}> = context => {
	const hostname = context.req.headers['host'];
	const forwardedHost = context.req.headers['x-forwarded-host'];
	const rqHost = forwardedHost || hostname;
	try {
		return Promise.resolve({
			props: {
				isKras: !rqHost?.includes('verras'),
			},
		});
	} catch (error) {
		debugPage(error);
		return Promise.resolve({
			props: {
				isKras: true,
			},
		});
	}
};

export default Root;
