import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useRouter } from 'next/router';

import { Box, Text, Image, Button } from '@ui/components';
import { styled } from '@ui/styles';
import { ControlRef } from '@ui/components/QrReader/types';

import TopCurveBox from '@common-lib/modules/landing/components/TopCurveBox';
import PrivacyLink from '@common-lib/components/layout/PrivacyLink';
import Loading from '@common-lib/components/Loading';
import useStatus from '@common-lib/modules/status/hooks';
import { qrCodes } from '@common-lib/modules/kras/config.client';
import { StatusType } from '@common-lib/modules/status/config';

const QRReader = React.lazy(
	() => import('@common-lib/modules/landing/components/QRReader'),
);

const AnimatedBox = styled(animated.div)(({ theme }) => ({
	textAlign: 'center',
	paddingBottom: theme.spacing(1),
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	justifyContent: 'center',
	backgroundImage: `url('/images/background.webp')`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	borderRadius: 32,
	backgroundPosition: 'top',
	margin: 14,
	// iphone only
	// '@supports (-webkit-touch-callout: none) ': {
	// 	paddingTop: theme.spacing(3),
	// 	flex: '0 0 auto',
	// },
}));

const HeadingWrapper = styled(Box)(({ theme }) => ({
	textAlign: 'center',
	width: '100%',
	zIndex: 5,
}));

const Wrapper = styled(Box)(({ theme }) => ({
	textAlign: 'center',
	marginLeft: '10%',
	marginRight: '10%',
}));

const TheImage = styled(Image)(({ theme }) => ({
	width: '100%',
	height: '100%',
	aspectRatio: 'auto 375 / 548',
}));
const TheImage2 = styled(Image)(({ theme }) => ({
	width: '90%',
	height: '100%',
	aspectRatio: 'auto 322 / 338',
}));

const Landing: React.FC<{ isKras: boolean }> = ({ isKras }) => {
	const { addError, clearStatus } = useStatus();
	const { replace } = useRouter();
	const [open, setOpen] = React.useState<boolean>(false);
	const props = useSpring({
		from: { opacity: 0, marginTop: 200 },
		to: { opacity: 1, marginTop: 14 },
		delay: 900,
	});

	const handleOpenCamera = React.useCallback(() => {
		setOpen(true);
	}, []);
	const handleScan = React.useCallback(
		(
			ref: React.RefObject<ControlRef>,
			result?: any | null,
			error?: Error | null,
		) => {
			const fout = 'Oeps. Er ging iets mis. Probeer het nog eens.';
			if (error) {
				addError('QR Code niet gelezen');
				return;
			}
			if (!result) {
				addError(fout);
				return;
			}
			const qrResult = result.text as string;
			if (
				!qrResult?.length ||
				!(
					qrResult.includes('_promo') ||
					qrResult.includes('qrcode.de') ||
					qrResult.includes('qrco.de')
				)
			) {
				addError('De QR Code bevat niet de juiste informatie.');
				return;
			}
			if (ref.current) {
				ref.current.stop();
				setOpen(false);
				clearStatus([StatusType.ERROR]);
			}
			// replace(qrResult);
			const qr = qrCodes.find(qr => qrResult.includes(qr));
			if (qr) {
				// this is a consumer qr code
				replace(`/qr/${qr}`);
			} else {
				// it's a verras qr code
				replace(`/verrassen	`);
			}
		},
		[],
	);
	return (
		<>
			<AnimatedBox style={props} className="wrapper-box bt-margin">
				<TopCurveBox>
					<Box
						sx={
							isKras
								? { marginRight: '12px', marginLeft: '-9px' }
								: undefined
						}
					>
						{isKras ? (
							<TheImage
								priority
								alt="keyvisual-consumer"
								src="/images/keyvisual-btc-ranja.webp"
								width={375}
								height={548}
							/>
						) : (
							<TheImage2
								priority
								alt="keyvisual-business"
								src="/images/keyvisual-business-2.webp"
								width={322}
								height={338}
							/>
						)}
					</Box>
					<HeadingWrapper>
						{isKras ? (
							<Text variant="h1" mt={-19}>
								Scan &amp; win!
							</Text>
						) : null}
					</HeadingWrapper>
				</TopCurveBox>
				<Wrapper mt={1}>
					{open ? (
						<React.Suspense fallback={<Loading />}>
							<QRReader onScanResult={handleScan} />
						</React.Suspense>
					) : (
						<>
							<Text
								variant="h4"
								component="p"
								gutterBottom
								color="text.primary"
							>
								{isKras
									? 'Scan de QR-code op een actieverpakking van Royal Club Ginger Beer, Rivella Original, Rivella Cranberry of Ranja Fruitmix en win leuke uitjes!'
									: 'Scan de QR-code op de crate cover van Royal Club Ginger Beer, Rivella Original, Rivella Cranberry of Ranja Fruitmix en maak kans op een solar bar!'}
							</Text>
							<Button
								sx={{ mt: 2 }}
								variant="contained"
								size="large"
								color="secondary"
								onClick={handleOpenCamera}
							>
								Open camera
							</Button>
						</>
					)}
					{/* <Finished qrCode="ABCDEF" userType={UserType.CONSUMENT} /> */}
				</Wrapper>
				{/* <Box p={2}>
					<ErrorCircle />
				</Box> */}
				{/* <Box>
					<Button variant="contained" onClick={handleClick}>
						Start
					</Button>
				</Box> */}
				<Box pb={1} mt={0.5}>
					<PrivacyLink isConsumer={isKras} />
				</Box>
			</AnimatedBox>
		</>
	);
};

export default Landing;
